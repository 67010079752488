<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <!-- 検索項目 -->
    <v-card class="mx-auto" style="height: 700px">
      <v-card-title>
        <p style="background: linear-gradient(transparent 50%, #ffff00 95%)">
          {{ $t("label.lbl_defectiveDiscardedFileUpload") }}
        </p>
      </v-card-title>
      <v-card-subtitle> </v-card-subtitle>
      <!-- 詳細入力 -->
      <div style="display: flex; justify-content: center; height: 450px">
        <v-form ref="detailInput" lazy-validation>
          <v-container fluid>
            <div>
              <div style="width: 1000px; float: left">
                <!-- データテーブル -->
                <v-data-table
                  id="listData"
                  fixed-header
                  :headers="headerItems"
                  :items="uploadFileList"
                  disable-filtering
                  disable-pagination
                  disable-sort
                  :hide-default-footer="true"
                  height="400px"
                  class="elevation-1"
                >
                </v-data-table>
              </div>
              <div style="float: left">
                <v-btn large @click="handleClickAppendOuter" text>
                  <v-icon>mdi-paperclip</v-icon>
                </v-btn>
              </div>
            </div>
            <!-- 取込ファイル -->
            <div class="file-input">
              <v-file-input
                prepend-icon=""
                :label="$t('label.lbl_importFile')"
                outlined
                dense
                v-model="importfile"
                counter
                append-outer-icon="mdi-paperclip"
                @click:append-outer="handleClickAppendOuter"
                @click="$event.target.value = ''"
                ref="vFileInput"
                multiple
                :disabled="testFlg && uploadFileList.length > 0"
              >
              </v-file-input>
            </div>
          </v-container>
        </v-form>
      </div>
      <v-row style="float: right">
        <v-card-actions>
          <v-col>
            <!-- 閉じるボタン -->
            <v-btn
              ref="closeBtn"
              color="primary"
              id="closeBtn"
              class="other-btn"
              right
              @click="viewConfirmDialog('btn_close')"
              >{{ $t("btn.btn_close") }}</v-btn
            >
            <!-- 登録ボタン -->
            <v-btn
              color="primary"
              id="btn-content-config"
              class="other-btn"
              @click="viewConfirmDialog('btn_insert')"
              :disabled="this.uploadFileList.length == 0"
              >{{ $t("btn.btn_insert") }}</v-btn
            >
          </v-col>
        </v-card-actions>
      </v-row>
    </v-card>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :workProcSeqImageFileUploadFlg="infoDialog.workProcSeqImageFileUploadFlg"
      :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      :firstPageFlag="infoDialog.firstPageFlag"
      :closeFlag="infoDialog.closeFlag"
      :okAction="infoDialog.okAction"
    />
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
export default {
  name: "DefectiveDiscardedDetailInputDialog",
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
  },
  // 親画面から渡される値
  props: {
    entity: { type: Object, default: null },
    isOpenFileUploadDialog: { type: Boolean, default: false },
    testFlg: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    uploadFileList: [],
    uploadFilePathMap: new Map(),
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    rules: {
      required: function (value) {
        if (value !== undefined && value !== null && value !== "") {
          value = String(value);
          value = value.replace(/\s+/g, "");
          if (value === "") {
            return i18n.tc("check.chk_input");
          }
        } else {
          return i18n.tc("check.chk_input");
        }
        return true;
      },
    },
    // ヘッダ
    headerItems: [
      // アップロードファイル
      {
        text: i18n.tc("label.lbl_uploadFile"),
        value: "name",
        width: "5%",
        align: "left",
        sortable: false,
      },
    ],
    importfile: null,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      workProcSeqImageFileUploadFlg: false,
      outsideClickNotCloseFlg: false,
      closeFlag: false,
      okAction: () => {},
    },
    // アップロードファイルサイズ
    currentUploadFileSize: 0,
    // アップロードファイルサイズ最大(単位：MB)
    uploadFileSizeMaxMB: 10,
    // アップロードファイルサイズ最大(単位：Byte)
    uploadFileSizeMaxByte: 10000000,
  }),

  methods: {
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let isValidationRequired;
      let message;
      let action;
      switch (button) {
        case "btn_insert":
          isValidationRequired = true;
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          action = this.insert;
          break;
        case "btn_close":
          isValidationRequired = false;
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.close;
          break;
        default:
          isValidationRequired = true;
          message = "";
      }
      if (isValidationRequired) {
        let isValid = this.$refs.detailInput.validate();
        if (!isValid) {
          return;
        }
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      this.loadingCounter = 0;
      this.uploadFileList = [];
      this.uploadFilePathMap = new Map();
      this.$refs.vFileInput.$el.style.display = "none";
      this.$refs.detailInput.resetValidation();
      this.currentUploadFileSize = 0;
      this.uploadFileSizeMaxMB = 0;
      this.uploadFileSizeMaxByte = 0;
      this.getCodeMst("1105");
    },
    /**
     * 閉じる処理
     */
    close() {
      this.$emit("update:isOpenFileUploadDialog", false);
    },
    /**
     * 登録API実行
     */
    insert() {
      // this.registrationApi();
      // データを保持して戻る。
      const inputList = this.entity.imageList;
      for (let i = 0; i < this.uploadFileList.length; i++) {
        this.uploadFileList[i].base64.then((base64) => {
          inputList.push({
            imageSid: null,
            fileName: this.uploadFileList[i].name,
            base64: base64,
            url: "",
          });
        });
      }
      this.entity.imageList = inputList;
      this.$emit("parentMethod");
      this.$emit("update:isOpenFileUploadDialog", false);
    },
    /**
     * 登録API実行
     */
    endEvent(button) {
      this.infoDialog.message = messsageUtil.getMessage("P-COM-001_005_C", [i18n.tc(button)]);
      this.infoDialog.title = "結果";
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      this.infoDialog.firstPageFlag = false;
      this.infoDialog.outsideClickNotCloseFlg = true;
      this.infoDialog.workProcSeqImageFileUploadFlg = true;
      this.infoDialog.closeFlag = true;
      this.close();
    },
    /**
     * 登録API実行
     */
    async registrationApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.alertMessage = "";
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = "P-MST-999"; //appConfig.SCREEN_ID.P_WST_002;
      body.reqIdv = { fileName: [], fileData: [] };

      for (let i = 0; i < this.uploadFileList.length; i++) {
        body.reqIdv.fileName.push(this.uploadFileList[i].name);
        this.uploadFileList[i].base64.then((base64) => {
          body.reqIdv.fileData.push(base64);
        });
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(
            /*appConfig.API_URL.DEFECTIVE_DISCARDED_INSERT_FOR_DIALOG*/ "init-master/a-proc-work-seq-image-upload",
            body
          )
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.endEvent("btn.btn_insert");
              // エラー時
            } else {
              this.loadingCounter = 0;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.loadingCounter = 0;
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {});
      });
    },
    /**
     * ファイルをBase64でエンコード
     */
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.readAsDataURL(file);
        reader.onload = function (event) {
          // DataURLからBase64の部分だけを取り出す
          const base64 = event.target.result.split(",")[1];
          resolve(base64);
        };
      });
    },
    /**
     * アイコンを押下しても、エクスプローラーが開く処理
     */
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
    /**
     * アップロード可能な拡張子かを判定する。
     */
    fileTypeAcceptCheck(file) {
      if (this.fileTypeAcceptList.includes(file.type) === false) {
        this.infoDialog.message = i18n.tc("check.chk_fileTypeNotAccepted");
        this.infoDialog.title = appConfig.DIALOG.title;
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
        return false;
      } else {
        return true;
      }
    },
    /**
     * コードマスタ取得
     */
    getCodeMst(codeType) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 検索条件などのパラメータを設定する
      config.params.isMst = "0";
      config.params.codeType = codeType;

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_CODE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              jsonData.resIdv.codeDefinition.forEach((row) => {
                list.push(row);
              });
              switch (codeType) {
                case "1105":
                  this.uploadFileSizeMaxMB = parseInt(list[0].codeInfoList[0].item02);
                  this.uploadFileSizeMaxByte =
                    parseInt(list[0].codeInfoList[0].item02) * 1024 * 1024;
                  break;
                default:
              }
              resolve();
              // エラー時
            } else {
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((resolve) => {
            console.error("GetParameter getCodeMst Err", resolve);
            reject(messsageUtil.getMessage("P-999-999_999_E"));
          });
      });
    },
    reload() {},
  },
  computed: {},
  watch: {
    isOpenFileUploadDialog: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    importfile(files) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let fileObj = { file: file, name: file.name, uploadPath: "", base64: "" };
        const previousFileCnt = this.uploadFilePathMap.size;
        this.uploadFilePathMap.set(file.name, fileObj);
        const currentFileCnt = this.uploadFilePathMap.size;
        if (previousFileCnt < currentFileCnt) {
          if (this.currentUploadFileSize + file.size < this.uploadFileSizeMaxByte) {
            fileObj.base64 = this.fileToBase64(fileObj.file);
            this.uploadFileList.push(Object.assign({}, fileObj));
            this.currentUploadFileSize = this.currentUploadFileSize + file.size;
          } else {
            this.uploadFilePathMap.delete(file.name);
            // ファイル名が同一の場合エラーとする。
            this.infoDialog.message = messsageUtil.getMessage("P-WST-001_001_E", [
              this.uploadFileSizeMaxMB,
            ]);
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
          }
        } else {
          this.uploadFilePathMap.delete(file.name);
          // ファイル名が同一の場合エラーとする。
          this.infoDialog.message = i18n.tc("check.chk_sameFileSelected");
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}
.v-data-table th {
  background: #dcdcdc;
}
.v-data-table td {
  background: white;
}
.v-data-table tr:nth-child(odd) td {
  background: white;
}
</style>
