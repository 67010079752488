<template>
  <div style="background: white; overflow-x: hidden">
    <Loading :loadingCounter="loadingCounter" />
    <Loading />
    <v-container fluid id="sub-bar">
      <v-col>
        <div id="lbl-screen-name">{{ $t("label.lbl_image") + $t("label.lbl_glance") }}</div>
      </v-col>
    </v-container>
    <!-- データテーブル -->
    <v-form ref="editedList" lazy-validation>
      <v-container fluid>
        <v-data-table
          id="listData"
          :headers="headerItems"
          :items="inputModalList"
          :fixed-header="true"
          disable-sort
          :hide-default-footer="true"
          disable-filtering
          height="657px"
        >
          <template v-slot:[`item.image`]="{ item }">
            <div v-if="item.sid == null" class="imgClass">
              <img :src="item.image" />
            </div>
            <div v-else>
              <iframe style="width: 100%; height: 300px" :src="item.url"></iframe>
            </div>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-btn small @click="deleteItem(item)" text>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-row style="float: right; margin: -12px -12px 0 -12px">
          <v-card-actions>
            <v-col>
              <!-- 閉じるボタン -->
              <v-btn
                ref="closeBtn"
                color="primary"
                id="closeBtn"
                class="other-btn"
                right
                @click="viewConfirmDialog('btn_close')"
                >{{ $t("btn.btn_close") }}</v-btn
              >
              <!-- 登録ボタン -->
              <v-btn
                color="primary"
                id="btn-content-config"
                class="other-btn"
                @click="viewConfirmDialog('btn_insert')"
                :disabled="deleteList.length == 0 && deleteImageList.length == 0"
                >{{ $t("btn.btn_insert") }}</v-btn
              >
            </v-col>
          </v-card-actions>
        </v-row>
      </v-container>
    </v-form>

    <ConfirmDialog
      :isShow.sync="confirmDialog.isOpen"
      :message="confirmDialog.message"
      :okAction="confirmDialog.okAction"
      :redMessage="confirmDialog.redMessage"
      :screenFlag="confirmDialog.screenFlag"
      :changeFlag="confirmDialog.changeFlag"
    />

    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :redMessage="infoDialog.redMessage"
      :screenFlag="infoDialog.screenFlag"
      :changeFlag="infoDialog.changeFlag"
      :workProcSeqImageFileUploadFlg="infoDialog.workProcSeqImageFileUploadFlg"
      :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      :firstPageFlag="infoDialog.firstPageFlag"
      :closeFlag="infoDialog.closeFlag"
      :okAction="infoDialog.okAction"
    />
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { i18n } from "../../lang/lang.js";
export default {
  name: "DefectiveDiscardedDetailInputDialog",
  components: {
    Loading,
    ConfirmDialog,
    SimpleDialog,
  },
  // 親画面から渡される値
  props: {
    entity: { type: Object, default: null },
    isOpenImage: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    deleteList: [],
    deleteImageList: [],
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    url: "",
    // ヘッダ
    headerItems: [
      // アップロードファイル
      {
        text: i18n.tc("label.lbl_image"),
        value: "image",
        width: "90%",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_delete"),
        value: "delete",
        width: "10%",
        align: "left",
        sortable: false,
      },
    ],
    inputModalList: [],
    dounloadFileList: [],
    int: 0,
    image: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      workProcSeqImageFileUploadFlg: false,
      outsideClickNotCloseFlg: false,
      closeFlag: false,
      okAction: () => {},
    },
  }),

  methods: {
    /**
     * ボタン押下時の処理
     */
    viewConfirmDialog(button) {
      let message;
      let action;
      switch (button) {
        case "btn_insert":
          message = messsageUtil.getMessage("P-COM-001_006_C", [i18n.tc("btn.btn_insert")]);
          action = this.insert;
          break;
        case "btn_close":
          message = messsageUtil.getMessage("P-COM-001_004_W");
          action = this.close;
          break;
        default:
          message = "";
      }
      this.confirmDialog.message = message;
      this.confirmDialog.title = appConfig.DIALOG.title;
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = action;
    },
    /**
     * 初期起動：画面を開いた際に渡された値を設定します。
     */
    init() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      this.inputModalList = [];
      this.dounloadFileList = [];
      this.deleteList = [];
      this.deleteImageList = [];
      this.int = 0;
      if (this.entity.imageList != null) {
        for (let i = 0; i < this.entity.imageList.length; i++) {
          this.inputModalList.push({
            image: "data:image/jpeg;base64," + this.entity.imageList[i].base64 ?? "",
            sid: this.entity.imageList[i].imageSid,
            delete: "",
            url: "",
            urlCode: this.entity.imageList[i].url,
            fileName: this.entity.imageList[i].fileName,
          });
          if (this.entity.imageList[i].imageSid != null) {
            this.dounloadFileList.push({
              count: i,
              url: this.entity.imageList[i].url,
            });
          }
        }
      }
      if (this.dounloadFileList.length > 0) {
        //データ検索
        this.referenceUrlGet();
      } else {
        // ローディング画面表示OFF
        this.loadingCounter = 0;
      }
    },
    /**
     * 参照URL取得
     */
    referenceUrlGet() {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = "P-MST-999";
      body.reqIdv = { kbn: "1", url: this.dounloadFileList[this.int].url };
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.INIT_PROC_SEQ_IMAGE_UPLOAD, body)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.inputModalList[this.dounloadFileList[this.int].count].url =
                jsonData.resIdv.fileId;
              // エラー時
            } else {
              this.loadingCounter = 0;
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.loadingCounter = 0;
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            this.int = this.int + 1;
            if (this.dounloadFileList.length > this.int) {
              this.referenceUrlGet();
            } else {
              this.loadingCounter = 0;
            }
          });
      });
    },
    /**
     * 削除アイコン押下時
     */
    deleteItem(item) {
      this.inputModalList.splice(this.inputModalList.indexOf(item), 1);
      if (item.sid != null) {
        this.deleteList.push({
          url: item.urlCode,
        });
      } else if (item.fileName) {
        this.deleteImageList.push({
          fileName: item.fileName,
        });
      }
    },
    /**
     * 閉じる処理
     */
    close() {
      this.$emit("update:isOpenImage", false);
    },
    /**
     * 登録API実行
     */
    insert() {
      this.entity.deleteList = this.deleteList;
      this.entity.deleteImageList = this.deleteImageList;
      this.$emit("isOpenClose");
      this.$emit("update:isOpenImage", false);
    },
    reload() {},
  },
  computed: {},
  watch: {
    isOpenImage: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.text-popBtn {
  padding-bottom: 30px;
}

.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

.v-data-table th {
  background: #dcdcdc;
}

.v-data-table td {
  background: white;
}

.v-data-table tr:nth-child(odd) td {
  background: white;
}

.imgClass {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
